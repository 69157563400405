import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { SocketioService } from '../socketio.service';
import { Location } from '@angular/common';
import * as OT from '@opentok/client';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private router:Router, private dataService: DataService, private formBuilder: FormBuilder, private socketService: SocketioService, private ref: ChangeDetectorRef, private location: Location) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
          return false;
      }; 
    this.changeDetectorRef = ref;
  }
  data:any = [];
  key = null;
  role_id = '0';
  user_id = '0';
  department_id:any = '0';
  notifications:any = {notifications:[]};
  billForm: FormGroup;
  billItems: FormArray;
  runNotifs:any;
  nextReg="";
  screenshotData:any;
  currentPage = "";
  allow_edit = 0;
  cc = "+91";

  newMessage: string;
  messageList: string[] = [];

  socket;

  session:OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  container: HTMLElement; 

  sender = [];
  caller = [];
  callStatus = 0;
  callType = "";
  callFromUser = 0;
  

  ngOnInit() {
    
    //console.clear();
    
    //Socket connection
    this.connectSocket();
    
    this.localSync();
    this.laPendingCount();

    //load key from localStrorage
    this.key = localStorage.getItem('key');
    this.role_id = localStorage.getItem('role_id');
    this.user_id = localStorage.getItem('user_id');
    this.department_id = localStorage.getItem('department');
    
    if(this.key == null) {
      //window.location.href = "./login";
      this.router.navigate(['/login']);
    }

    console.log("dataService: ", this.dataService.localData.roleId);
    console.log("localStorage: ", localStorage.getItem('role_id'));
    

  	this.dataService.headBar().subscribe( (data: any )=> {
      this.data = data;
      this.allow_edit = data.allow_edit;
      localStorage.setItem('allow_edit', data.allow_edit);
      localStorage.setItem('user_id', data.user_id);
      localStorage.setItem('department', data.department);
      localStorage.setItem('isHod', data.is_hod);
      this.dataService.localData.isHod = data.is_hod;
      this.dataService.localData.deptId = data.department;

    } );

    this.loadNotifications();
    //this.runNotifs = window.setInterval(() => this.loadNotifications(), 60000);

    
    this.billForm = this.formBuilder.group({
        item: '',
        rate: '',
        qty: '',
        amount:0,
        billItems:this.formBuilder.array([this.createBillItem()])
      });


    //datepicker
    
    $( function() {
      (<any>$(".date-picker" )).datepicker({
          dateFormat: "dd-mm-yy",
          beforeShow: function() {
              setTimeout(function(){
                  $('.ui-datepicker').css('z-index', 99999999999999);
              }, 0);
          },
          onSelect: function (dateText, inst) {
          this.focus();
          }
      });
    });

   //capture screenshot
   let context = this;
   $('body').on('click', '#capture', function(){
     //@ts-ignore
      html2canvas(document.querySelector("body"), {

      }).then(canvas => {
          //document.body.appendChild(canvas);
          $('#screenshot').html(canvas);
          $('#screenshot canvas').css('width', '700px');
          $('#screenshot canvas').css('height', 'auto');
          $('#modal-report-issue').modal('show');

          context.screenshotData = canvas.toDataURL("image/png");
          


          
          
        });
    });

   //videocall

   this.getSender();

   //Listen for new video call

    this.socketService.onNewVideoCall().subscribe( data => {
      console.log('new videocall', data);

      this.callType = "video";
      //@ts-ignore
      if (data.callToId == this.sender.id) {
        this.caller = data;
        $('#modal-receive-call').modal();

        //get patient id from callFromId

      } else {
        
      }
      
    });

    this.socketService.onNewAudioCall().subscribe( data => {
      console.log('new audio call', data);
      this.callType = "audio";
      //@ts-ignore
      if (data.callToId == this.sender.id) {
        this.caller = data;
        $('#modal-receive-call').modal();
        console.log('This call is for you');
      } else {
        console.log('This call is not for You');
      }
      
      
    });


    this.socketService.onRejectCall().subscribe( data => {
       console.log(data);
       this.callStatus = 0;
       this.session.disconnect();
    });

  }

  ngOnDestroy() {
    if (this.runNotifs) {
      clearInterval(this.runNotifs);
      
    }
  }

  loadNotifications() {
    this.dataService.getNotibar().subscribe( (data: any[] ) =>{
        this.notifications = data;
        
    });
  }

  laPendingCount(){
    this.dataService.getLaPendingCount().subscribe( (data: any ) =>{
      this.dataService.localData.laPendingCount = data.count;
      
    });
  }

  clearNotifications(){
    this.dataService.postClearNotifications().subscribe( (res:any) => {
        if(res.status == 0) {
          this.loadNotifications();
        }

    } );
  }

  createBillItem(): FormGroup {
      return this.formBuilder.group({
        item: '',
        rate: '',
        qty: '',
        amount:0,
      })
    }

    addBillItem():void {
      this.billItems = this.billForm.get('billItems') as FormArray;
      this.billItems.push(this.createBillItem());
    }

    removeBillItem(index) {
      this.billItems.removeAt(index);

    }


  submitRegistration(data) {
    data.dob = $('#inputDOB').val();
    data.name = $('#inputName').val();
    data.oldregno = $('#inputReg').val();

    if(data.phone1 == null || data.phone1.length == 0) {
      data.phone1 = $('#inputPhone').val();
    }

    
    data.eid = $('#inputEid').val();
    data.patient_since = $('#inputSince').val();

    $('#btn-reg1').attr('disabled', 'true');
    $('#btn-reg1').text('Submitting');

    var treatments = [];

    $('ul.treatment-selector li').each(function(){
      if( $(this).hasClass('active') ) {
        treatments.push( $(this).text() );
      }
    })

    data.treatments = treatments;

    
    
    this.dataService.postRegistration(data).subscribe( (res:any) => {

      $('#btn-reg1').removeAttr('disabled');
      $('#btn-reg1').text('Submit');
      
      if(res.status == 0) {
        alert(res.msg);
        $('#addPatient').modal('hide');
        this.router.navigate(['/dashboard/patient/'+res.patient_id+'/details']);
        
      } else {
        alert(res.msg);
      }

    }); 
    
    

    
  }

  


  submitEnquiry(data) {

    $('#btn-submit-enquiry').attr('disabled', 'true');
    $('#btn-submit-enquiry').text('Submitting');

    var enqtypes = [];

    $('ul.enquiry-type li').each(function(){
      if( $(this).hasClass('active') ) {
        enqtypes.push( $(this).text() );
      }
    })

    data.enq_types = enqtypes;

    this.dataService.postEnquiry(data).subscribe( (res:any) => {
      if(res.status == 0) {
          alert(res.msg);
          $('#enquiryModal').modal('hide');
          $('#btn-submit-enquiry').removeAttr('disabled');
          $('#btn-submit-enquiry').text('Submit');
          
          this.router.navigate(['/dashboard/enquiry']);      
          
        } else {
          alert(res.msg);
        }
    } );
  }

  submitEvent(data) {
    data.date = $('#event-date').val();
    
    $('#btn-submit-event').attr('disabled', 'true');
    $('#btn-submit-event').text('Submitting');




    this.dataService.postEvent(data).subscribe( (res:any) => {
      if(res.status == 0) {
          alert(res.msg);
          $('#eventModal').modal('hide');
          $('#btn-submit-event').removeAttr('disabled');
          $('#btn-submit-event').text('Submit');
          this.router.navigate(['/dashboard/events']);      
          
          
        } else {
          alert(res.msg);
        }
    } );
    

    

    
  }

  /*billing */

  submitBill(){
      $('#btn-submit').attr('disabled', 'true');
      $('#btn-submit').text('Submitting');

      var data = {"patient_id": $('.patient-details').attr('data-patient') , "billcontents":[]};
      var bc = [];

      $('.ab-row').each(function(){
        
        if ($(this).find('.ab-item').val() !== "" &&  $(this).find('.ab-rate').val() !== "" ) {
            
            var row = {
              "description":$(this).find('.ab-item').val(),
              "rate": $(this).find('.ab-rate').val(),
              "qty": $(this).find('.ab-qty').val(),
            }

            bc.push(row);

        }

        
      });

      if (bc.length > 0) {
        //
        data.billcontents = bc;
          this.dataService.postBill(data).subscribe( (res:any) => {
            $('#btn-submit').removeAttr('disabled');
            $('#btn-submit').text('Add');

            if(res.status == 0) {
              alert(res.msg);
              $('#modalBilling').modal('hide');
              this.router.navigate(['/dashboard/billing']);
              

              
            } else {
              alert(res.msg);
            }

          });
        //
      } else {
          alert('Bill cannot be empty');
          $('#btn-submit').removeAttr('disabled');
          $('#btn-submit').text('Add');
      }

      
        
      


  }

  showPatientSelect() {
      $('.patient-details').hide();
    $('.patient-details').attr('data-patient', 0);
    $('.input-select-patient').val('');
    $('.input-select-patient').show();
    }

    newPatient() {
      
      this.dataService.getNextPatientId().subscribe( (data: any[] ) =>{
          //@ts-ignore
          if (data.status == 0) {
            //@ts-ignore
            this.nextReg = data.reg_no;
          }
          
      });
      
    }

    selectRegNo(){
      $('#inputReg').val(this.nextReg);
    }

    markRead(id) {
      var data = {"id":id};

      this.dataService.postMarkRead(data).subscribe( (res:any) => {
        console.log(res);
        if(res.status == 0) {
             this.loadNotifications();
            
          }
      } );

    }



  signOut(){

    this.dataService.localData.filterTag = "";
    this.dataService.localData.datePatientsToday = "";
    this.dataService.localData.dateDashboard = "";
    this.dataService.localData.dateDashboardReception = "";
    this.dataService.localData.dateSchedule = "";
    this.dataService.localData.dateScheduleIndividual = "";
    this.dataService.localData.deptSchedule = 0;
    this.dataService.localData.deptScheduleName = "";
    this.dataService.localData.billContents = [];
    this.dataService.localData.billingFilterDate = "";
    this.dataService.localData.billingFilterSearch = "";
    this.dataService.localData.billingOffset = 0;


    localStorage.removeItem('key');
    localStorage.removeItem('role_id');
    this.router.navigate(['/login']);
  }

  submitIssue(data){

    $('#btn-report-issue').attr('disabled', 'true');
    $('#btn-report-issue').text('Sending...');

    
    data.page = window.location.href;
    data.screenshot = this.screenshotData;
    this.dataService.postReportIssue(data).subscribe( (res:any) => {
      $('#btn-report-issue').removeAttr('disabled');  
      $('#btn-report-issue').text('Send');
      alert(res.msg);
      if (res.status == 0) {
        $('#modal-report-issue').modal('hide');
      }

    });

  }

  connectSocket(){
    this.socketService.setupSocketConnection();
  }

  joinRoom(roomId) {
    this.socketService.joinRoom(roomId);
  }

  sendMessage(roomId, message){
    var msg = {
                
                  text:message,
                  roomId: roomId,
                  user: {
                          _id: '30',
                          name: 'Maya',
                          avatar: 'https://randomuser.me/api/portraits/men/76.jpg',
                        },
              };

    var data = {
                  from: {
                    name: 'Maya',
                      userId: 30,
                        },
                  to: {
                    name: 'Lester',
                      userId: 10,
                        onesignalId: '18bc8411-f4d0-461e-9262-970507ca7b9e',
                        },
                  roomId: roomId,
                    message: msg,
                      token: localStorage.getItem('key'),
                        pushContent: '',
                };

    this.socketService.sendMessage(data);
  }

  getSender(){

    this.dataService.getSender().subscribe( (data: any[] )=> {
      
      this.sender =  data;
      console.log('sender', data);
      
    });

  }

  acceptIncomingVideoCall(){
    $('#modal-receive-call').modal('hide');
    //@ts-ignore
    console.log('caller', this.caller);

    //Find caller's patient ID
    //@ts-ignore
    this.dataService.getCallingPatient(this.caller.callFromId).subscribe( (data: any[] )=> {
      //@ts-ignore
      if (data.status == 0) {
        var options = 'location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,width=1490,height=800';
        //@ts-ignore
        window.open('video-call/'+data.patient_id+'/'+this.caller.sessionId, 'null', options);
      }
      
    });


    /*
    this.socketService.joinOpenTokSession(this.sender.id, this.caller.sessionId).subscribe( sessionToken => {

        
      //@ts-ignore
      this.socketService.receiveSession(this.caller.sessionId, sessionToken).then((session: OT.Session) => {
        
        this.callStatus = 1;
        this.session = session;
        this.session.on('streamCreated', (event) => {
          this.streams.push(event.stream);
          //this.changeDetectorRef.detectChanges();
          this.ref.detectChanges();
        });
        this.session.on('streamDestroyed', (event) => {
          console.log('Stream destroyed');
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.ref.detectChanges();
            
          }
        });

        this.session.on("sessionDisconnected", function(event) {
           console.log('Session disconnected');
           
       });

      })
      .then(() => this.socketService.connectTok())
      .catch((err) => {
        console.error(err);
        alert('Unable to connect. ');
      });
     
    });
    */
      
  }

  rejectCall(){
    
    $('#modal-receive-call').modal('hide');
    //@ts-ignore
    this.socketService.rejectCall(this.caller.roomId);
    this.callStatus = 0;
    console.log('session disconnected');
    
  } 


  goBack(){
    this.location.back();
  }

  localSync(){

  	//remarks

  	var empty = {};

  	if (localStorage.getItem("remarksStorage") === null) {
	  localStorage.setItem( "remarksStorage", JSON.stringify(empty) );
	}

	if (localStorage.getItem("sdStorage") === null) {
	  localStorage.setItem( "sdStorage", JSON.stringify(empty) );
	}



  }


}
