import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../data.service';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-denver',
  templateUrl: './denver.component.html',
  styleUrls: ['./denver.component.scss']
})
export class DenverComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService) { }
  id = 0;
  denverCoords = [];
  dataDates = [];
  selectedDates = [];
  comments = [];
  comment = "";
  selectedComment = {id:0, comment:''};
  //@ts-ignore
  today = new Date();
  color = "Red";
  ngOnInit() {

  	let context = this;

  	this.route.parent.params.subscribe((params:any) => { 
      this.id = +params["id"];

    });

  	
    document.getElementById("denver").addEventListener('mousedown', function(e) {
        //context.drawLine(e); 
        //context.showModal(e);
        context.clickedCanvas(e);
    })

    this.retrieveCoordinates();
    this.loadComments();

  }

  clickedCanvas(e){
    //@ts-ignore
    var rect = denver.getBoundingClientRect();
    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;
    var found = 0;
    
    //check if the click was around any symbols
    //@ts-ignore
    this.denverCoords.every(function(k, i){
      if(k.type == 2) {
        
        if(x > k.x-5 && x < k.x+20 && y > k.y-5 && y < k.y+20){
          found = k.id;
          
        }
      }

      if(found != 0){
        return false;
      } else {
        return true;
      }

      
    });

    if(found != 0) {
      var cnf = confirm("Do you want to delete this mark?");
      if(cnf) {
        let data= {patient_id: this.id, found: found, type:"denver"};
        this.dataService.postDeleteCanvasCoords(data).subscribe( (res:any) => {
          if(res.status == 0){
            this.retrieveCoordinates();
          } else {
            alert(res.msg);
          }
          
        });
      }
    } else {
      this.showModal(e);
    }

    
  }

  drawLine(){
  		//@ts-ignore
  		var rect = denver.getBoundingClientRect();
	   	//var x = event.clientX - rect.left;
	   	//var y = event.clientY - rect.top;

      //@ts-ignore
      var x = $('#x_val').val() - rect.left;
      //@ts-ignore
      var y =$('#y_val').val() - rect.top;

	   	this.plotLine(x);
      $('#modal-options').modal('hide');
        //save coords to db
        var data = {patient_id:this.id, x:x, y:y, type:1};
	   	this.dataService.postDenverCoords(data).subscribe( (res:any) => {
          this.retrieveCoordinates();
      });


  }

  insertOption(option){
  	
  	var x_val = $('#x_val').val();
    var y_val = $('#y_val').val();
    

    //@ts-ignore
    var ctx = document.getElementById("denver").getContext('2d');
    //@ts-ignore
    var rect = denver.getBoundingClientRect();
  	//@ts-ignore  
    var x = x_val - rect.left - 10;
    //@ts-ignore
    var y = y_val - rect.top-10;

    
    this.drawOption(x, y, option);
    $('#modal-options').modal('hide');

    //save to db

    let data = {
    	patient_id:this.id, 
    	x:x, 
    	y:y,
    	option:option,
    	type: 2
    };

    

    this.dataService.postDenverCoords(data).subscribe( (res:any) => {
      this.retrieveCoordinates();
    });

  }

  insertOptionText(option, color){
    var x_val = $('#x_val').val();
    var y_val = $('#y_val').val();
    var text = "";
    if(option == 1){
      text = "✓";
    } else {
      text = "⨯";
    }

    //@ts-ignore
    var ctx = document.getElementById("denver").getContext('2d');
    //@ts-ignore
    var rect = denver.getBoundingClientRect();
  	//@ts-ignore  
    var x = x_val - rect.left; // - 10;
    //@ts-ignore
    var y = y_val - rect.top; //-10;

    ctx.fillStyle = color;
    ctx.font = "30px Arial";
    ctx.fillText(text, x, y);

    $('#modal-options').modal('hide');

   
    //save to db

    let data = {
    	patient_id:this.id, 
    	x:x, 
    	y:y,
    	option:option,
    	type: 2,
      date: this.today,
      color:this.color,
    };

    console.log(data.date);

    this.dataService.postDenverCoords(data).subscribe( (res:any) => {
      this.retrieveCoordinates();
    });

  }

  drawOptionText(x, y, option, color){
    console.log(x,y);
    if(color == null || color == ""){
      color = "black";
    }

    var text = "";
    if(option == 1){
      text = "✓";
    } else {
      text = "⨯";
    }

    //@ts-ignore
    var ctx = document.getElementById("denver").getContext('2d');
    //@ts-ignore
    var rect = denver.getBoundingClientRect();
  	

    ctx.fillStyle = color;
    ctx.font = "30px Arial";
    ctx.fillText(text, x, y);

    

    

  }

  retrieveCoordinates(){
  	//@ts-ignore
    var ctx = document.getElementById("denver").getContext("2d");
    //@ts-ignore
    ctx.clearRect(0,0,denver.width, denver.height);

    //prepare datestring;
    var ds = "";
    this.selectedDates.forEach(function(val, i){
      ds += val + ",";
    });

    this.dataService.getDenverCoords(this.id, ds).subscribe( (data: any )=> {
        
        
        //@ts-ignore
        this.denverCoords = data.data; 
        console.log("coords", this.denverCoords);
        //@ts-ignore
        this.dataDates = data.dates;
        let context = this;
        
        if (this.denverCoords.length > 0) {
        	this.denverCoords.forEach(function(val,i) {
        		if(val.type == 1) {
              context.plotLine(val.x);
            } else {
              context.drawOptionText(val.x, val.y, val.option, val.color);
            }
        	})
        }

      
    } );
  }

  showModal(event){
  	$('#x_val').val(event.clientX);
    $('#y_val').val(event.clientY);
    
    $('#modal-options').modal();
  }

  plotLine(x){
  	let ytop = 99;
    let ybottom = 1047;
    //@ts-ignore
      var ctx = document.getElementById("denver").getContext("2d");
    ctx.strokeStyle = "#ff2626"; // Red color
    ctx.lineWidth = 4;

    ctx.beginPath();
    ctx.moveTo(x, ytop);
    ctx.lineTo(x, ybottom);
    ctx.stroke(); 
  }

  postComment(data){

  	$('#btn-comment').attr('disabled','true');

  	data.patient_id = this.id;
    data.type = 1;
  	let push = {id: 0, comment:data.comment, user: '', time: '', type:1};
  	this.comments.push(push);
  	this.dataService.postDenverComments(data).subscribe( (res:any) => {
  		$('#btn-comment').removeAttr('disabled');
        if (res.status == 0) {
        	this.comment = "";
        	this.loadComments();
        } else {
        	alert(res.msg);
        }

    });

  }

  loadComments(){
  	this.dataService.getDenverComments(this.id, 1).subscribe( (data: any )=> {
        this.comments = data;
        console.log('comments', data);
    } );
  }

  removeLastLine(){
  	let data = {patient_id:this.id};
  	this.dataService.postDeleteLastDenver(data).subscribe( (res:any) => {
  		
        if (res.status == 0) {
        	this.retrieveCoordinates();
        } else {
        	alert(res.msg);	
        }

        

    });
  }

  drawOption(x, y, option){
  	let image = new Image();
    image.src = (option == 0) ?  'assets/img/icon-close-red.svg' : 'assets/img/icon-check.svg'  ;
    //@ts-ignore
    var ctx = document.getElementById("denver").getContext('2d');
    image.onload = function(){
    	ctx.drawImage(image, x,y, 20,20);	
    }

    
    

  }

  deleteNote(id){
    var data = {id:id};

    var cnf = confirm("Do you want to delete this note?");

    if(cnf) {
      this.dataService.postDeleteDenverComments(data).subscribe( (res:any) => {
        if(res.status == 0){
          this.loadComments();
        } else {
          alert(res.msg);
        }
  
      });
    }

    

  }

  selectComment(comment){
    this.selectedComment = comment;
    $('#modal-edit-note').modal('show');
  }

  editComment(data){
    this.dataService.postEditDenverComments(data).subscribe( (res:any) => {
      if(res.status == 0){
        this.loadComments();
        $('#modal-edit-note').modal('hide');
      } else {
        alert(res.msg);
      }

    });
  }

  selectDate(d){
    if(this.selectedDates.indexOf(d) > -1){
      this.selectedDates.splice(this.selectedDates.indexOf(d));
    } else {
      this.selectedDates.push(d);
    }

    this.retrieveCoordinates();

  }

}
