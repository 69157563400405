import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private dataService: DataService, private formBuilder: FormBuilder) { }
  data:any = {};
  leaveData:any = [];
  role = localStorage.getItem('role_id');
  profileForm: FormGroup;
  leaves:any = {leaves:[]};

  //new leave module

  startDate:any = null;
  endDate:any = null;
  dayLength:any = 0; //Full day
  halfDay:any = 3; //3 - FN, 4 - AN;


  ngOnInit() {
  	this.loadProfile();
    this.loadLeaves();
    this.loadLeaveApplications();

    this.profileForm = this.formBuilder.group({
      photo: [''],
      name: "",
      email:"",
      mobile:"",
      about:""
    });

  }

  loadProfile(){
  	this.dataService.getUserProfile().subscribe( (data: any[] )=> {
	  		this.data = data;
	  		
	  		
	  } );
  }

  loadLeaves(){
    this.dataService.getUserLeaves().subscribe( (data: any[] )=> {
        this.leaves = data;
        
        
      } );
  }

  loadLeaveApplications(){
    this.dataService.getUserLeaveApplications().subscribe( (data: any[] )=> {
      this.leaveData = data;
      
      
    });

  }

  submitPassword(data) {
  	if(data.new_password === data.cnew_password) {

      $('#btn-pwd').attr('disabled', 'true');
      $('#btn-pwd').text('Submitting');

  		this.dataService.postPassword(data).subscribe( (res:any) => {
        $('#btn-pwd').text('Submit');
        $('#btn-pwd').removeAttr('disabled');
    		if(res.status == 0) {
          alert(res.msg);
          
        } else {
          alert(res.msg);
        }
      });
    		
    	} else {
  		  alert("New password doesn't match.");
  	  }



  }

  submitLeave() {
    var data = {"leave_date": $('#input-leave').val() };
    
    
    this.dataService.postLeave(data).subscribe( (res:any) => {
      if(res.status == 0) {
        
        this.loadLeaves();
        //@ts-ignore
        document.getElementById("formLeave").reset(); 
      } else {
        alert(res.msg);
      }


    }); 

  }

  submitLeaveApplication(data) {
    
    

    this.dataService.postLeaveApplication(data).subscribe( (res:any) => {
      if(res.status == 0) {
        
        this.loadLeaveApplications();
        //@ts-ignore
        document.getElementById("formLeave").reset(); 
      } else {
        alert(res.msg);
      }


    });

  }


  onName(val) {
    this.profileForm.get('name').setValue(val);
  }
  onEmail(val) {
    this.profileForm.get('email').setValue(val);
  }
  onMobile(val) {
    this.profileForm.get('mobile').setValue(val);
  }
  onAbout(val) {
    this.profileForm.get('about').setValue(val);
  }

  onFileSelect(event) {

    if(event.target.files.length > 0) {

      const file = event.target.files[0];
      this.profileForm.get('photo').setValue(file);

    }

  }


  submitProfile(){
    $('#btn-profile').attr('disabled', 'true');
    $('#btn-profile').text('Submitting');

    const formData = new FormData();
    formData.append('name', this.profileForm.get('name').value);
    formData.append('email', this.profileForm.get('email').value);
    formData.append('mobile', this.profileForm.get('mobile').value);
    formData.append('about', this.profileForm.get('about').value);
    formData.append('photo', this.profileForm.get('photo').value);

    this.dataService.postProfile(formData).subscribe( (res:any) => {
      
      alert(res.msg);

      if(res.status == 0) {
        $('#btn-profile').removeAttr('disabled');
        $('#btn-profile').text('Save');
        $('#profileModal').modal('hide');
        this.loadProfile();
      }


    });

  }

  deleteLa(la){

    let data = {id:la.id};
    var cnf = confirm("Do you really want to delete this leave request?");

    if(cnf){
      this.dataService.postDeleteLa(data).subscribe( (res:any) => {
        if(res.status == 0){
          this.loadLeaveApplications();
        } else {
          alert(res.msg);
        }
      });
    }

    

  }

  changeDayLength(val){

    if(val > 1){
      this.dayLength = 1;
    } else {
      this.dayLength = val;
    }

  }

  changeHalfDay(val){
    this.halfDay = val;
  }

  

}
