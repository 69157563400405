import { Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';

import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; 
import { SocketioService } from '../../socketio.service';
import * as OT from '@opentok/client';




@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit, OnDestroy {
	
  
  data:any = {op_number:'', patient_name:'', member_since:'', departments:[], age:'', gender:'', alerts:[], };
  id = '0';
  role_id= '0';
  bill_total = 0;
  billForm: FormGroup;
  billItems: FormArray;
  uploadForm: FormGroup;
  uploadChat: FormGroup;
  error:string;
  fileUpload = {status: '', message: '', filePath: ''};
  medForm: FormGroup;
  medItems: FormArray;
  devForm: FormGroup;
  devItems: FormArray;
  proForm: FormGroup;
  proItems: FormArray;
  tForm: FormGroup;
  tItems: FormArray;
  role = localStorage.getItem('role_id');
  dept = localStorage.getItem('department');
  upFiles = [];
  runAutoSaveRem:any;
  runAutoSaveUf:any;
  autoSaveRemId = 0;
  autoSaveRemData = {};
  tags = [];
  ufFileOpenFlag = 0;
  ufFileUploadFlag = 0;
  uffileDeleteFlag = 0;
  ufc = [];
  unsavedRemarks = 0;
  gcCoords = [];
  tforms = [];

  isHometherapy = 0;
  chatRoomId = 0;
  chatState = 0;
  chatHistory = [];
  messageText = "";

  sender:any;
  recipient:any;

  socket;

  /* video chat */

  session:OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  callStatus = 0;

  container: HTMLElement; 
  caller = [];

  htOverview = {status: 0, activities: []};
  chatUnreadExists = 0;
  chatImageBox = 0;
  chatImageUrl = "";

  displayCallingText = 1;
  displayCallAgain = 0;

  /*unified form */
  showUnified = 0;
  unifiedForms = [];
  selectedForm = {};
  responseTypes = {};
  formsOffset = 0;
  closingVisit:any = 0;
  selectedBill:any = {};
  selectedVisit:any = {};


  constructor( private router:Router, private route: ActivatedRoute, private dataService: DataService, private formBuilder: FormBuilder, private ngxService: NgxUiLoaderService, private socketService: SocketioService, private ref: ChangeDetectorRef) {
    this.changeDetectorRef = ref;
  }

  ngOnInit() {
    this.role_id = localStorage.getItem('role_id');
  	this.id = this.route.snapshot.paramMap.get('id');
    
    this.loadPatient();
    this.loadHtOverview();
    this.loadTags();
    
    this.loadToolsForms();
    
    this.socketService.onNewMessage().subscribe( msg => {
      
      if (this.chatState == 0) {
        this.chatUnreadExists = 1;
      }

      console.log('new message', msg);
      if (msg.user._id == this.recipient.id) {
        var history = {
                       "message_id":0,
                       "from":0,
                       "to":0,
                       "class": "chat-them",
                       "message": msg.text,
                       "image": msg.image,
                       "sent_time":msg.createdAt ,
                       "is_read":0,
                       "activity_id":0,


                    };
      } else {
        var history = {
                       "message_id":0,
                       "from":0,
                       "to":0,
                       "class": "chat-me",
                       "message": msg.text,
                       "image": msg.image,
                       "sent_time":msg.createdAt ,
                       "is_read":0,
                       "activity_id":0,


                    };
      }
      

      this.chatHistory.push(history);
      if (this.chatState == 1) {
        $(".chat-wrap").animate({
          //@ts-ignore
          scrollTop: $('.chat-wrap')[0].scrollHeight
        }, 1000);
      }
      

    });

    //Listen for new video call
    /*
    this.socketService.onNewVideoCall().subscribe( data => {
      console.log('new videocall', data);

      if (data.callToId == this.sender.id) {
        this.caller = data;
        $('#modal-receive-call').modal();
      } else {
        console.log('This call is not for You');
      }

    });

    */

    this.socketService.onRejectCall().subscribe( data => {
       console.log('call rejected');
       this.session.disconnect();
       this.displayCallAgain = 1;
       this.closeCamera();
    });

    
    
     

    //@ts-ignore
    $('.rich-editor').trumbowyg({
      btns: [

            
            ['formatting'],
            ['undo', 'redo'], // Only supported in Blink browsers
            ['strong', 'em', 'del'],
            ['superscript', 'subscript'],
            ['unorderedList', 'orderedList'],
            ['horizontalRule'],
            //['removeformat'],
            
        ]
    });


    
    //auto-save remarks;
    let context = this;
    
    $('body').on('keyup', '#remarksBox .trumbowyg-editor', function(){
      context.syncRemarks( $(this).html() );
    });

    $('body').on('shown.bs.modal', '#modalRemarks', function(){
      context.loadRemarks();

      
    });

    
    $("body").on("keyup", "#chat_text", function(e){
      if (e.keyCode == 13) {
          
          context.sendMessage();
      }
    })

    
    

    this.billForm = this.formBuilder.group({
      item: '',
      rate: '',
      qty: '',
      amount:0,
      billItems:this.formBuilder.array([this.createBillItem()])
    });

    this.uploadForm = this.formBuilder.group({
      doc: [''],
      file_title: "",
    });
    this.uploadChat = this.formBuilder.group({
      doc: [''],
      file_title: "",
    });

    this.medForm = this.formBuilder.group({
      name:'',
      dosage:'',
      frequency:'',
      medItems:this.formBuilder.array([this.createMedItem()])
    });

    this.devForm = this.formBuilder.group({
      name:'',
      dosage:'',
      frequency:'',
      devItems:this.formBuilder.array([this.createDevItem()])
    });

    this.proForm = this.formBuilder.group({
      name:'',
      dosage:'',
      frequency:'',
      proItems:this.formBuilder.array([this.createProItem()])
    });

    this.tForm = this.formBuilder.group({
      name:'',
      dosage:'',
      frequency:'',
      tItems:this.formBuilder.array([this.createTItem()])
    });


    

    //Load unified forms;

    //this.loadForms(0);
    
  }

  ngOnDestroy() {
    //this.clearAutoSave();

    this.id = '0';
    $('.trumbowyg-editor').off("keyup");

    if (this.runAutoSaveRem) {
      clearInterval(this.runAutoSaveRem);
    }

    if (this.chatRoomId != 0 ) {
      this.socketService.leaveRoom(this.chatRoomId);
    }
  }

  
  

  

  createBillItem(): FormGroup {
    return this.formBuilder.group({
      item: '',
      rate: '',
      qty: '',
      amount:0,
    })
  }

  addBillItem():void {
    this.billItems = this.billForm.get('billItems') as FormArray;
    this.billItems.push(this.createBillItem());
  }

  removeBillItem(index) {
    this.billItems.removeAt(index);

  }


  createMedItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      dosage: '',
      frequency: '',
    })
  }

  addMedItem():void {
    this.medItems = this.medForm.get('medItems') as FormArray;
    this.medItems.push(this.createMedItem());
  }

  removeMedItem(index) {
    this.medItems.removeAt(index);

  }

  //device formarray

  createDevItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      dosage: '',
      frequency: '',
    })
  }

  addDevItem():void {
    this.devItems = this.devForm.get('devItems') as FormArray;
    this.devItems.push(this.createDevItem());
  }

  removeDevItem(index) {
    this.devItems.removeAt(index);

  }

  //pro formarray

  createProItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      dosage: '',
      frequency: '',
    })
  }

  addProItem():void {
    this.proItems = this.proForm.get('proItems') as FormArray;
    this.proItems.push(this.createProItem());
  }

  removeProItem(index) {
    this.proItems.removeAt(index);

  }

  //test formarray

  createTItem(): FormGroup {
    return this.formBuilder.group({
      name: '',
      dosage: '',
      frequency: '',
    })
  }

  addTItem():void {
    this.tItems = this.tForm.get('tItems') as FormArray;
    this.tItems.push(this.createTItem());
  }

  removeTItem(index) {
    this.tItems.removeAt(index);

  }




  loadPatient(){
    this.dataService.getPatientProfile(this.id).subscribe( (data: any[] )=> {

      this.data = data;
      //@ts-ignore
      this.getHometherapy(data.phone);
      this.startChat();
    } );
  }

  closeVisit(visitId){
    
    //check if remark has been added for today's date;

    this.dataService.getRemarkStatus(this.id).subscribe( (data: any )=> {
      if(data.status == 0 && data.note_found == true){
        //close appointment;
        var cnf = confirm("Do you really want to mark this session as completed?");

        if(cnf){
          var data_  = {"appointment_id":visitId};
          this.dataService.postMarkComplete(data_).subscribe( (res: any) => {
            if(res.status == 0){
              $('#modalRemarks').modal('hide');
              this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
            } else {
              alert(res.msg);
            }

          });
        }

      } else {
        //show modal;
        this.closingVisit = visitId;
        $("#modalRemarks").modal("show");
        

      }
      
    } );
    
    
    

  }
  
  submitNotes(data){
    data.patient_id = this.id;
    data.date = $('#note-date').val();
    data.note_contents = $('#note-contents').val();
    data.note_id = this.autoSaveRemId;
    $('#btn-remarks').attr('disabled', 'true');
    $('#btn-remarks').text('Submitting');
    data.visit_id = this.closingVisit;
    

    this.dataService.postNotes(data).subscribe( (res:any) => {
      this.closingVisit = 0;
      if(res.status == 0) {
        this.clearRemarks();
        alert(res.msg);
        $('#modalRemarks').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        alert(res.msg);
      }


    });

    


    
    
  }
  

  submitMedications(){

    $('#btn-medication').attr('disabled', 'true');
    $('#btn-medication').text('Submitting');

    var data = {"patient_id":this.id, "medications":[]};
    var medications = [];
    $('.am-row').each(function(){

      var d = { 
            "brand_name": $(this).find('.am-medicine').val(), 
            "regime": $(this).find('.am-dosage').val(), 
            "no_of_days": $(this).find('.am-frequency').val(),
            }

      medications.push(d);

    });
    
    data.medications = medications;

    this.dataService.postMedications(data).subscribe((res:any) => {
      if(res.status == 0) {
        alert(res.msg);
        $('#modalMedication').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        alert(res.msg);
      }
    } );

  }

  submitDevices(){

    $('#btn-devices').attr('disabled', 'true');
    $('#btn-devices').text('Submitting');

    var data = {"patient_id":this.id, "devices":[]};

    var devices = [];

    jQuery('.ad-row').each(function(){
      var device = {
        "device_id":0,
        "device_name": $(this).find('.ad-device').val(),
        "specification": $(this).find('.ad-spec').val(),
        "notes": $(this).find('.ad-notes').val(),
        "quantity":1
      }

      devices.push(device);
    });

    data.devices = devices;
    this.dataService.postDevices(data).subscribe((res:any) => {
      if(res.status == 0) {
        alert(res.msg);
        $('#modalDevice').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        alert(res.msg);
      }
    } );
  }

  submitProsthetics(){

    $('#btn-prosthetics').attr('disabled', 'true');
    $('#btn-prosthetics').text('Submitting');

    var data = {"patient_id":this.id, "prosthetics":[]};
    var prosthetics = [];

    jQuery('.ap-row').each(function(){
      var device = {
        "description": $(this).find('.ap-name').val(),
        "spec": $(this).find('.ap-spec').val(),
        "notes": $(this).find('.ap-notes').val(),
      }


      prosthetics.push(device);
    });

    data.prosthetics = prosthetics;
    this.dataService.postProsthetics(data).subscribe((res:any) => {
      if(res.status == 0) {
        alert(res.msg);
        $('#modalProsthetics').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        alert(res.msg);
      }
    } );
  }

  submitTests() {

    $('#btn-tests').attr('disabled', 'true');
    $('#btn-tests').text('Submitting');

    var data = {"patient_id":this.id, "tests":[]};
    var tests = [];

    jQuery('.at-row').each(function(){
      var test = {
        "test": $(this).find('.at-name').val(),
        "notes": $(this).find('.at-notes').val()
      };

      tests.push(test);
    });

    data.tests = tests;
    
    this.dataService.postTests(data).subscribe( (res:any) => {
      if(res.status == 0) {
        alert(res.msg);
        $('#modalTest').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        alert(res.msg);
      }
    });
  }

  submitRefer(){

    $('#btn-refer').attr('disabled', 'true');
    $('#btn-refer').text('Submitting');

    var data = {
      "patient_id":this.id,
      "referred_department": $('.ref-department').attr('data-department'),
      "referred_to": $('.ref-doctor').attr('data-doc'),
      "notes": $('.ref-notes').val(),
      }

    

    this.dataService.postRefer(data).subscribe( (res:any) => {
      if(res.status == 0) {
        alert(res.msg);
        $('#modalRefer').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        alert(res.msg);
      }
    });
  }

  submitDischarge(data){
    $('#btn-discharge').attr('disabled', 'true');
    $('#btn-discharge').text('Submitting');
    data.patient_id = this.id;
    

    this.dataService.postDischarge(data).subscribe( (res:any) => {
      alert(res.msg);

      if(res.status == 0) {
        $('#modalDischarge').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      }


    } );
  }

  onFileSelect(event) {

    
    if(event.target.files.length > 0) {
      
      
      this.uploadForm.get('doc').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }

    }
    

  }

  onFileTitle(val) {
    this.uploadForm.get('file_title').setValue(val);
  }

  submitFile() {
    this.ngxService.start('loader-01'); //progress
    
    $('#btn-file').attr('disabled', 'true');
    $('#btn-file').text('Uploading');

    const formData = new FormData();
    if(this.upFiles.length){
       for(let i=0 ; i < this.upFiles.length ; i++)
         formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
    }

    formData.append('file_title', this.uploadForm.get('file_title').value);
    formData.append('patient_id', this.id);
     
    
    this.dataService.postFile(formData).subscribe( (res:any) => {
      
      //alert(res.msg);
      
      if(res.status == 0) { 
        this.ngxService.stop('loader-01');
        $('#btn-file').removeAttr('disabled');
        $('#btn-file').text('Upload');
        $('#modalFileUpload').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        this.ngxService.stop('loader-01');
        $('#btn-file').removeAttr('disabled');
        $('#btn-file').text('Upload');
        alert(res.msg);

      }
     


    });



    
  }

  submitBill(){

    $('#btn-bill').attr('disabled', 'true');
    $('#btn-bill').text('Submitting');

    var data = {"patient_id":this.id, "billcontents":[]};
    var bc = [];

    $('.ab-row').each(function(){
      
      var row = {
        "description":$(this).find('.ab-item').val(),
        "rate": $(this).find('.ab-rate').val(),
        "qty": $(this).find('.ab-qty').val(),
      }

      bc.push(row);
    });

    data.billcontents = bc;

    this.dataService.postBill(data).subscribe( (res:any) => {

      if(res.status == 0) {
        alert(res.msg);
        $('#modalBilling').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      } else {
        alert(res.msg);
      }

    } );  
  }



  endSession(id) {
    var data = {"appointment_id":id};
    this.dataService.endSession(data).subscribe( (res: any) => {
      if(res.status == 0){
        this.loadPatient();
      } else {
        alert(res.msg);
      }

    });
  }

  submitEdit(data){
    data.patient_id = this.id;
    data.apl_bpl = $('input[name="apl_bpl"]:checked').val();
    data.gender = $('input[name="gender"]:checked').val();
    data.employment_status = $('input[name="employment_status"]:checked').val();
    data.docinstructions = $('input[name="docinstructions"]:checked').val();
    data.examination = $('input[name="examination"]:checked').val();
    data.patient_since = $('#editSince').val();
    data.dob = $('#editDOB').val();

    var treatments = [];

    $('ul.edit-treatments li').each(function(){
      if( $(this).hasClass('active') ) {
        treatments.push( $(this).text() );
      }
    })

    data.treatments = treatments;

    $('#btn-edit').text('Submitting');
    $('#btn-edit').attr('disabled', 'true');
    

    
    this.dataService.postEditPatient(data).subscribe( (res:any) => {
      $('#btn-edit').text('Submit');
      $('#btn-edit').removeAttr('disabled');
      if(res.status == 0){
        $('#editPatient').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/details']);
      } else {
        alert(res.msg);
      }

    });
    
  }

  autoSaveRemarks(){

    var today = new Date();
    
    //console.log( 'autosave_id - ' + this.autoSaveRemId);
    if (this.checkIfEmpty() == 0 ) {
      var data = {
        "patient_id":this.id,
        "remarks_id":this.autoSaveRemId,
        "remarks":$('#note-contents').val(),
      };

      if (JSON.stringify(this.autoSaveRemData) !== JSON.stringify(data) ) {
        this.autoSaveRemData = data;

        this.dataService.postAutoSaveNotes(data).subscribe( (res:any) => {
          
          if(res.status == 0) {
            this.autoSaveRemId = res.remarks_id;

          } else {
            //console.log(res.msg);
          }


        });
        

      }

    }
    
    
  }
  

  checkIfEmpty(){
    var status = 1;
    if ($('#note-contents').val() != "" ) {
      status = 0;

    }

    return status;

  }

  clearAutoSave(){
    console.log('clearing autosave', this.runAutoSaveRem);

    if (this.runAutoSaveRem) {
      console.log('trying');
      this.autoSaveRemId = 0;
      clearInterval(this.runAutoSaveRem);

    }

    console.log('cleared autosave', this.runAutoSaveRem);
    
  }

  getHometherapy(phone){
    this.dataService.getHometherapy(phone).subscribe( (data: any[] )=> {
      
      //@ts-ignore
      if (data.status == 0) {
        this.isHometherapy = 1;
      }
      
    } );
  }

  toggleChat(){
    this.chatUnreadExists = 0;

    if (this.chatState == 0) {
      this.chatState = 1;
      window.setTimeout(() => this.scrollChat(), 100)
      //this.scrollChat();
      
    } else {
      this.chatState = 0;
    }
  }

  scrollChat(){
    
    $('#chat_text').focus();
    if (this.chatState == 1) {
      $(".chat-wrap").animate({
        //@ts-ignore
        scrollTop: $('.chat-wrap')[0].scrollHeight
      }, 1000);
    }
  }

  startChat(){

    this.dataService.getChatRoom(this.data.phone).subscribe( (data: any[] )=> {
      
      //@ts-ignore
      this.chatRoomId = parseInt(data.room_id);
      this.socketService.joinRoom(this.chatRoomId);
      this.getChatHistory(this.chatRoomId);
    } );

    this.getSender();
    this.getRecipient(this.data.phone);


  }

  sendMessage(){

    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    
    //var messageText = this.messageText.replace("\n", "");
    var messageText = this.messageText.trim();

    if (messageText.length > 0) {
      //
      var msg = {
                
              text:this.messageText ,
              roomId: this.chatRoomId,
              user: this.sender,
              createdAt: date + ' ' + time ,
              _id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          };
      
      
      
      var data = {
                    from: {
                      name: this.sender.name,
                        userId: this.sender.id,
                          },
                    to: {
                      name: this.recipient.name,
                        userId: this.recipient.id,
                          onesignalId: this.recipient.onesignal_id,
                          },
                    roomId: this.chatRoomId,
                      message: msg,
                        token: localStorage.getItem('key'),
                          pushContent: '',
                  };

      
      
      var history = {
         "message_id":0,
         "from":0,
         "to":0,
         "class": "chat-me",
         "message": msg.text,
         "sent_time":date + ' ' + time ,
         "is_read":0,
         "activity_id":0,


      };
      
      
      this.messageText = "";
      this.chatHistory.push(history);
      $(".chat-wrap").animate({
        scrollTop: $('.chat-wrap')[0].scrollHeight
      }, 1000);

      this.socketService.sendMessage(data);
      $('#chat_text').focus();
      
    }


    

    


  }

  getSender(){

    this.dataService.getSender().subscribe( (data: any[] )=> {
      
      this.sender =  data;
      
      
    });

  }

  getRecipient(phone){
    
    this.dataService.getRecipient(phone).subscribe( (data: any[] )=> {
      this.recipient =  data;
      
      
    });
  }

  getChatHistory(roomId){
    this.dataService.getChatHistory(roomId).subscribe( (data: any[] )=> {
      
      //@ts-ignore
      this.chatHistory = data.messages;
    });
  }

  loadMoreChatHistory(){
    var chatId = this.chatHistory[0].message_id;
    this.dataService.getMoreChatHistory(this.chatRoomId, chatId).subscribe( (data: any[] )=> {
      
      //@ts-ignore
      let god = this;
      //@ts-ignore
      data.messages.forEach(function(k, i){
        god.chatHistory.unshift(k);
      });

      
    });

  }


  /* videochat */



  videochatConnect(){

    this.socketService.getOpenTokSession(this.sender.id).subscribe( data => {
      //this.sendVideocall(data.sessionId);
      
      this.socketService.initSession(data.sessionId, data.sessionToken).then((session: OT.Session) => {
        this.session = session;
        this.sendVideocall(data.sessionId);
        this.session.on('streamCreated', (event) => {
          console.log('streamCreated', event);
          this.displayCallingText = 0;
          this.streams.push(event.stream);
          this.changeDetectorRef.detectChanges();
        });
        this.session.on('streamDestroyed', (event) => {
          console.log('stream destroyed', event);
          this.closeCamera();
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.changeDetectorRef.detectChanges();
          }
        });
      })
      .then(() => this.socketService.connectTok())
      .catch((err) => {
        this.closeCamera();
        //console.log('unable to connect', err);
        alert('Unable to connect.');
        
      });


    });
    



    
  
  }

  initiateChat(){
    this.callStatus = 1;
    this.displayCallingText = 1;
    this.displayCallAgain = 0;
    this.videochatConnect();
    

  }

  sendVideocall(sessionId){
    
    var data = {
      roomId: this.chatRoomId,
      sessionId: sessionId,
      onesignalId: this.recipient.onesignal_id,
      //onesignalId: 'c8284b29-98d5-4d3c-baf2-dbf5e011f634',
      callType: 'video',
      callFromId: this.sender.id,
      callFromName: this.sender.name,
      callToId: this.recipient.id,
      callToName: this.recipient.name,
      token: localStorage.getItem('key'),
    };

    console.log('callData', data);

    this.socketService.startVideoCall(data);
  }

  rejectCall(){
    this.session.disconnect();
    //this.session.unsubscribe();
    this.socketService.rejectCall(this.chatRoomId);
    //this.callStatus = 0;
    $('#modal-receive-call').modal('hide');
    this.displayCallAgain = 1;
    this.displayCallingText = 0;
    this.closeCamera();
     
  }

  closeCallWindow(){
    this.rejectCall();
    this.callStatus = 0;

  }


  submitSym(data) {
    data.patient_id = this.id;

    this.dataService.postHtSymptoms(data).subscribe( (res:any) => {
      alert(res.msg);

      if (res.status == 0) {
          //@ts-ignore
         document.getElementById("formSym").reset(); 
         this.loadHtOverview();
      }


    });

  }

  resetFormSym(){
    //@ts-ignore
    document.getElementById("formSym").reset(); 
  }

  loadHtOverview(){

    this.dataService.getHtPatientOverview(this.id).subscribe( (data: any[] )=> {
      //@ts-ignore
      this.htOverview = data;
      
      
    } );

  }

  openChatImage(image){
    this.chatImageUrl = image;
    this.chatImageBox = 1;
  }

  closeChatImage(){
    this.chatImageUrl = "";
    this.chatImageBox = 0;
  }
   


  closeCamera(){
    console.log('Trying to stop camera');
    navigator.mediaDevices.getUserMedia({video: true, audio: false})
      .then(mediaStream => {
        const stream = mediaStream;
        console.log('stream', stream);
        stream.getTracks().forEach(function(track) {
          console.log('track', track);
          track.stop();
        });

      });

  }


  toggleUnified(val){

    if (val == 0) {
      this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
    } else {
      this.dataService.getUnifiedCredentials(this.id).subscribe( (data: any[] )=> {
          this.ufc = data;
          console.log('ufc', data);
      });

      //this.runAutoSaveUf = window.setInterval(() => this.saveForm(0), 2000);

    }

    this.showUnified = val;
  }


  loadForms(form_id){

    this.dataService.getUnifiedForms(this.formsOffset, this.id, 4).subscribe( (data: any[] )=> {
        this.unifiedForms = data;
        //@ts-ignore
        if (data.forms.length > 0) {
          //@ts-ignore
          if (form_id == 0) {
            //@ts-ignore
            this.loadForm(data.forms[0].id);
          } else {
            this.loadForm(form_id);
          }
          
        }

    });

  }

  formsSlideRight(){
    this.formsOffset += 4;
    this.loadForms(0);
  }

  formsSlideLeft(){
    this.formsOffset -= 4;
    this.loadForms(0);
  }

  loadForm(form_id) {
    this.dataService.getUnifiedForm(form_id, this.id).subscribe( (data: any[] )=> {
        this.selectedForm = data;
        if (form_id == 36) {
          this.dataService.getUnifiedCredentials(this.id).subscribe( (data: any[] )=> {
            this.ufc = data;
            
          });
        }
    });
  }


  selectOption(card, question, option) {

    
    //@ts-ignore
    if (this.selectedForm.data[card].questions[question].options[option].state == 1) {
    //@ts-ignore
    this.selectedForm.data[card].questions[question].options[option].state = 0;
    } else {
      //@ts-ignore
      var context = this;

      
      //@ts-ignore
      this.selectedForm.data[card].questions[question].options.forEach(function(i, k) {
        
        if (i.is_multiselect == 0) {
          i.state = 0
        }
      })
      //@ts-ignore
      this.selectedForm.data[card].questions[question].options[option].state = 1;
    }
    
   

    
  }

  setOption(i, value){
    console.log('val', value);
  }

  updateResponse(card, question, option, value) {
    if (value.length > 0) {
      //@ts-ignore
      this.selectedForm.data[card].questions[question].options[option].state = 1;
    } else {
      //@ts-ignore
      this.selectedForm.data[card].questions[question].options[option].state = 0;
    }
    //@ts-ignore
    this.selectedForm.data[card].questions[question].options[option].response = value;

  }

  skipForm(){
    //get next form and load that.
    
    $('.btn-skip').addClass('disabled');
    //@ts-ignore
    this.dataService.getSkipUnifiedForm(this.selectedForm.id).subscribe( (data: any[] )=> {
        $('.btn-skip').removeClass('disabled');
        //@ts-ignore
        if (data.status == 0) {
          //@ts-ignore
          this.loadForm(data.form_id);
        } else {
          //@ts-ignore
          alert(data.msg);
        }

    });

  }

  saveForm(showActivity){
    var data = {"patient_id":this.id, "form":this.selectedForm, "showActivity":showActivity};
    if (showActivity == 1) {
      $('.btn-save').addClass('disabled');
    }

    console.log('data', data);
    
    this.dataService.postSaveUnifiedForm(data).subscribe( (res:any) => {
      //$('.btn-submit').removeAttr('disabled');
      $('.btn-save').removeClass('disabled');
      console.log(res);
      if(res.status == 0) {
        //@ts-ignore
        //this.loadForm(this.selectedForm.id);

        if (showActivity == 1) {
          //@ts-ignore
          if (this.selectedForm.id == this.unifiedForms.last_form) {
            this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
          } else {
            this.skipForm();  
          }
        }
        

        
      } else {

        if (showActivity == 1) {
          alert(res.msg);
        }
        
      }


    });

  }

  signForm(){
    var data = {"patient_id":this.id};
    $('.btn-save').addClass('disabled');

    this.dataService.postSignUnifiedForm(data).subscribe( (res:any) => {
      $('.btn-save').removeClass('disabled');
      if(res.status == 0){
        this.router.navigate(['/dashboard/patient/'+this.id+'/overview']);
      }
      
    });

    
  }

  openVidCall(){
    var options = 'location=no,toolbar=no,menubar=no,scrollbars=yes,resizable=yes,width=1490,height=800';

    window.open('video-call/'+this.id, 'null', options);
  }

  //File upload in text chat

  openFileWindow(){
    $('#patientFileInput').click();
  }

  onChatFileSelect(event) {
    
    
    if(event.target.files.length > 0) {
      console.log('yay');
      
      this.uploadChat.get('doc').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }
      
      const formData = new FormData();

      if(this.upFiles.length){
         for(let i=0 ; i < this.upFiles.length ; i++)
           formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
      }
      formData.append('patient_phone', this.data.phone);

      this.dataService.postChatFile(formData).subscribe( (res:any) => {
        //emit to socket
        this.upFiles = [];
        var msg= {
          id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
          image: res.file_url,
          roomId: this.chatRoomId,
          user: {
            _id: this.sender.id,
            name: this.sender.name,
            avatar: "https://met-public.s3.ap-south-1.amazonaws.com/icon-user.png"
          }
        };


        var data = {
          from: {
              name: this.sender.name,
              userId: this.sender.id,
                },
          to: {
              name: this.recipient.name,
              userId: this.recipient.id,
              onesignalId: this.recipient.onesignal_id,
              },
          roomId: this.chatRoomId,
          message: msg,
          token: localStorage.getItem('key'),
          pushContent: 'New image received',
      };

      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

      var history = {
         "message_id":0,
         "from":0,
         "to":0,
         "class": "chat-me",
         "message": "",
         "image": msg.image,
         "sent_time":date + ' ' + time ,
         "is_read":0,
         "activity_id":0,


      };

      this.chatHistory.push(history);
      $(".chat-wrap").animate({
        scrollTop: $('.chat-wrap')[0].scrollHeight
      }, 1000);

      this.socketService.sendMessage(data);
      $('#chat_text').focus();


    });


      
      

    }
    

  }

  addTag(tag) {

    var data = {patient_id:this.id, tag:tag};
    this.dataService.postAddTag(data).subscribe( (res:any) => {
       
       if (res.status == 0) {
         this.data.tag_string = res.tag_string;
         this.data.tags = res.tags;
       }

    });
  }

  loadTags(){
    this.dataService.getTags().subscribe( (data: any[] )=> {
        this.tags = data;
        console.log('tags', this.tags);
      } );
  }

  submitTags() {
    $('#modalTags').modal('hide');
    this.loadPatient();
  }

  uploadUfFile(card, question, option, event){
    if(event.target.files.length > 0) {
      
      this.ufFileUploadFlag = 1;

      //Upload file and update response;

      this.uploadForm.get('doc').setValue(event.target.files);
      
      for(let i=0 ; i < event.target.files.length ;i++){ 
        this.upFiles.push(<File>event.target.files[i]);
      }

      const formData = new FormData();
      if(this.upFiles.length){
         for(let i=0 ; i < this.upFiles.length ; i++)
           formData.append('file[]', this.upFiles[i],this.upFiles[i].name);
      }

      //formData.append('file_title', 'file');
      formData.append('patient_id', this.id);
      //@ts-ignore
      formData.append('option_id', this.selectedForm.data[card].questions[question].options[option].option_id);

      this.dataService.postFileUf(formData).subscribe( (res:any) => {
        this.ufFileUploadFlag = 0;
        console.log('upload', res);
        
        if(res.status == 0) {
          //@ts-ignore
          this.selectedForm.data[card].questions[question].options[option].state = 1;
          //@ts-ignore
          this.selectedForm.data[card].questions[question].options[option].response = res.file;
        } else {
          alert(res.msg);
        }
       


      });

      

    }
  }

  openUfFile(key) {
    this.ufFileOpenFlag = 1;
    this.dataService.getOpenFile(key).subscribe( (data: any[] )=> {
      this.ufFileOpenFlag = 0;
      //@ts-ignore
      if (data.status == 0) {
        //@ts-ignore
        window.open(data.url);
      }
      
    } );
    
  }

  deleteUfFile(card, question, option){
    this.uffileDeleteFlag = 1;
    //@ts-ignore
    var data = {option: this.selectedForm.data[card].questions[question].options[option].option_id, patient:this.id }
    this.dataService.postDeleteUfFile(data).subscribe( (res:any) => {
      this.uffileDeleteFlag = 0;
      if(res.status == 0) {
        //@ts-ignore
        this.selectedForm.data[card].questions[question].options[option].state = 0;
        //@ts-ignore
        this.selectedForm.data[card].questions[question].options[option].response = "";

      } else {
        alert(res.msg);
      }


    });

  }

  
  syncRemarks(val){
    var remarksStorage = JSON.parse(localStorage.getItem('remarksStorage'));
    remarksStorage[this.id] = val;
    localStorage.setItem('remarksStorage', JSON.stringify(remarksStorage));
  }

  loadRemarks(){
    $('#remarksBox .trumbowyg-editor').html('');
    setTimeout(function() {
          $('#remarksBox .trumbowyg-editor').focus();
      }, 0);


    if (localStorage.getItem("remarksStorage") !== null) {
      var remarks = JSON.parse(localStorage.getItem("remarksStorage"));

      if (remarks[this.id] !== undefined ) {
        $('#remarksBox .trumbowyg-editor').html(remarks[this.id]);
        if (remarks[this.id] != "" ) {
          this.unsavedRemarks = 1;
        }
        
      }


    }
  }

  clearRemarks(){
    if (localStorage.getItem("remarksStorage") !== null) {
      var remarks = JSON.parse(localStorage.getItem("remarksStorage"));

      if (remarks[this.id] !== undefined ) {
        remarks[this.id] = "";
        localStorage.setItem('remarksStorage', JSON.stringify(remarks) );
      }
    }
  }

  



  

  submitRecNote(data){
    data.patient_id = this.id;

    this.dataService.postRecNotes(data).subscribe( (res:any) => {
      if(res.status == 0) {
        $('#modalRecNotes').modal('hide');
        this.router.navigate(['/dashboard/patient/'+this.id+'/details']);
      } else {
        alert(res.msg);
      }


    });
  }

  openSciProfile(){
     this.dataService.getSciToken().subscribe( (data: any[] )=> {
        //@ts-ignore
        if(data.status == 0){
          //@ts-ignore
          //var profile =  "http://localhost/sci/frontend/web/?r=app/patient&patient_id="+this.id+"&token="+data.token;
          var profile =  "https://app.metanoa.ai/sci/frontend/web/?r=app/patient&patient_id="+this.id+"&token="+data.token;
          window.open(profile);
        }

      
    } ); 
  }

  loadToolsForms(){
    this.dataService.getToolsForms().subscribe( (data: any[] )=> {
      this.tforms  = data;
      
    } );
  }

  deleteTag(tag) {
    var data = {patient_id:this.id, tag:tag};
    
    this.dataService.postDeleteTag(data).subscribe( (res:any) => {
      if(res.status == 0) {
        
        this.data.tag_string = res.tag_string;
        this.data.tags = res.tags;
      } else {
        alert(res.msg);
      }


    });

  }

  viewBill(visit){

    //Get bill;
    
    this.dataService.getSingleBill(visit.bill_id).subscribe( (data: any )=> {
      this.selectedBill = data.data;
      
    } );

  }

  confirmVisitPayment(visit_id){

    var cnf = confirm("Clicking OK/Yes means you have confirmed that the client has made the payment for this session.");

    this.dataService.getConfirmVisitPayment(visit_id).subscribe( (data: any )=> {
      this.loadPatient();
      $("#modalBill").modal("hide");
    } );
  }


}
