import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	data:any = {upcoming_appointments:[], consultations:[], recent_appointments:[], date:"", today:""};
	selectedDate = '';
	dept = [];
	user = localStorage.getItem('user_id');
  	constructor(private dataService: DataService, private router:Router) { }

	ngOnInit() {
		this.selectedDate = this.dataService.getLocalData("dateDashboard");
		this.loadDashboard();
		this.getDept();

		console.log("date", this.selectedDate);

		$( function() {
			(<any>$("#schedule-date" )).datepicker({
		    	dateFormat: "dd-mm-yy",
		    	onSelect: function (dateText, inst) {
					this.focus();

			    }
			});

			
		});

	}

	timerOut(){
		var date1 = new Date();
		var date2 = new Date('2019-12-02 17:06:04');
		var diff = 0;
		//diff = date1 - date2;
		//return diff;
	}

	loadDashboard(){
		this.dataService.getTherapistDashboard(this.selectedDate).subscribe( (data: any[] )=> {
			this.data = data;
			
		} )
	}

	getDept(){
		this.dataService.getDept().subscribe( (data: any[] )=> {
			this.dept = data;
			
		} )
	}

	endSession(id) {
		var data = {"appointment_id":id};
		this.dataService.endSession(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	startSession(id) {
		var data  = {"appointment_id":id};
		this.dataService.startSession(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	markComplete(visit) {
		
		this.dataService.getRemarkStatus(visit.patient_id, this.selectedDate).subscribe( (data: any )=> {
			if(data.status == 0 && data.note_found == true){

				var data_  = {"appointment_id":visit.appointment_id};
				this.dataService.postMarkComplete(data_).subscribe( (res: any) => {
					if(res.status == 0){
						this.loadDashboard();
					} else {
						alert(res.msg);
					}

				});

			} else {
				//redirect to patient profile after an alert;
				alert("You need to add a remark before you can mark this session as complete. You will be redirected to the patient's profile now.");
				this.router.navigate(['/dashboard/patient/'+visit.patient_id+'/overview']);
			}
		});

		
	}

	markConsultation(id) {
		var data  = {"id":id};
		this.dataService.postMarkConsultation(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	undo(id, type) {
		var data = {"id":id, "type":type};
		this.dataService.postUndoCompleted(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	markAbsent(id){
		var data  = {"id":id};
		this.dataService.postMarkAbsent(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	markPresent(id){
		var data  = {"appointment_id":id};
		this.dataService.postMarkPresent(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	undoAbsent(id) {
		console.log(id);
		var data  = {"id":id};
		this.dataService.postUndoAbsent(data).subscribe( (res: any) => {
			if(res.status == 0){
				this.loadDashboard();
			} else {
				alert(res.msg);
			}

		});
	}

	switchDate(date) {
		this.selectedDate = date;
		this.dataService.setLocalData("dateDashboard", date);
		this.loadDashboard();
	}

	submitConsultation2(){

	  	var data = {
	  		"patient_id": $('#inputConsultation3').attr('data-patient'),
	  		"date":this.selectedDate,
	  		//@ts-ignore
	  		"consultant":this.user,
	  		"type": $('#inputConsultation3').attr('data-type'),
	  		"time": $('#inputConsultationTime').val(),
	  	};

	  	this.dataService.postConsultation(data).subscribe( (res:any) => {
		        if(res.status == 0) {
		        	//@ts-ignore
		            this.loadDashboard();
		       		
		       		$('#inputConsultation3').val('');
		       		$('#inputConsultation3').attr('data-patient', 0);
		       		$('#modalConsultation').modal('hide');
		          } else {
		            alert(res.msg);
		          }
		      } );
	  	
	  }
	


}
