import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
//import { throwError } from 'rxjs';
//import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
	
  key = localStorage.getItem("key");
  //public REST_API_SERVER = "http://localhost/nipmr-api/frontend/web/?r="; //development
  public REST_API_SERVER = "https://pdata-api.metanoa.ai/?r="; //staging
  //public REST_API_SERVER = "https://nipmr-api.metanoa.ai/?r="; //mirror
  //public REST_API_SERVER = "https://api.metanoa.ai/?r="; //production-nipmr
  //public REST_API_SERVER = "https://testing-api.metanoa.ai/?r="; //testing
  
  

  //private HT_API = 'http://localhost/ht-api/frontend/web/?r=';
  private HT_API = 'https://ht-api.metanoa.ai/?r=';
  
  //private INVENTORY_API = 'http://localhost/nipmr-inventory-api/frontend/web/?r=';
  private INVENTORY_API = "https://nipmr-inventory-api.metanoa.ai/?r=";
  
  private filterTag = "";
  public localData = {
    filterTag:"", 
    datePatientsToday:"", 
    dateDashboard:"", 
    dateDashboardReception:"", 
    dateSchedule:"", 
    dateScheduleIndividual:"",
    deptSchedule:0,
    deptScheduleName:"",
    orgId:"",
    userId: (localStorage.getItem('user_id') !== null) ? localStorage.getItem('user_id') : "",
    roleId: (localStorage.getItem('role_id') !== null) ? localStorage.getItem('role_id') : "",
    isHod: 0,
    deptId:0,
    billContents:[],
    billingFilterDate:"",
    billingFilterSearch:"",
    billingOffset:0,
    slotEnquiry:{patient_name:""},
    laPendingCount:0,
  };


  constructor(private httpClient: HttpClient) { }

  setLocalData(option, value){
    this.localData[option] = value;
    
    
  }

  getLocalData(option){
    return this.localData[option];
  }
    
    public headBar() {
      return this.httpClient.get(this.REST_API_SERVER + "account/headbar", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientTags(){
  		return this.httpClient.get(this.REST_API_SERVER + "therapist/patient_tags", {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getAnalytics(period, date1, date2){
  		return this.httpClient.get(this.REST_API_SERVER + "analytics/therapist&period="+period+"&date1="+date1+"&date2="+date2, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}
    public getAnalyticsAdmin(period, date1, date2, therapist, dept){
  		return this.httpClient.get(this.REST_API_SERVER + "analytics/admin&period="+period+"&date1="+date1+"&date2="+date2+"&therapist_id="+therapist+"&dept="+dept, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getAnalyticsAdminBilling(period, date1, date2, therapist, dept){
  		return this.httpClient.get(this.REST_API_SERVER + "analytics/admin_billing&period="+period+"&date1="+date1+"&date2="+date2+"&therapist_id="+therapist+"&dept="+dept, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

  	public getTherapistDashboard(date){
  		return this.httpClient.get(this.REST_API_SERVER + "therapist/dashboard&date="+date, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getReception(limit, department){
      return this.httpClient.get(this.REST_API_SERVER + "reception/dashboard"+"&limit="+limit+"&department="+department , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public getReceptionRecent(limit){
      return this.httpClient.get(this.REST_API_SERVER + "reception/recent&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getSchedules(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/schedules", {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public loadReschedule(appointment_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/get_patient_by_appointment&appointment_id="+appointment_id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getDate(date, dept_id, custom, cancelView){
      return this.httpClient.get(this.REST_API_SERVER + "reception/schedules&selected="+date+"&dept="+dept_id+"&custom_date="+custom+"&cancel_view="+cancelView , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public getAppointments(date, user_id, cancelView, dept){
      return this.httpClient.get(this.REST_API_SERVER + "reception/appointments&date="+date+"&user_id="+user_id+"&cancel_view="+cancelView+"&dept="+dept , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getSlotNotes(date, dept){
      return this.httpClient.get(this.REST_API_SERVER + "reception/load_notes&date="+date, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getIndividualSchedule(date, doc_id, cancelView){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/schedules&selected="+date+"&doc_id="+doc_id+"&cancel_view="+cancelView , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatient(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/get_patient&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getUpcoming(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/upcoming&appointment_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getGroupAppointments(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/group_appointments&appointment_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getVisitDetails(id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/visit_details&appointment_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getViewSlot(slot_id, therapist_id, date, selected){
      return this.httpClient.get(this.REST_API_SERVER + "reception/view_slot&slot_id="+slot_id+"&therapist_id="+therapist_id+"&date="+date+"&selected="+selected , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientAppointments(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/patient_appointments&patient_id="+patient_id , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getViewVisit(appointment_id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/view_visit&appointment_id="+appointment_id , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

  	public getPatientProfile(id){
  		return this.httpClient.get(this.REST_API_SERVER + "patient/profile&patient_id="+id, {
  			headers: { 'key': localStorage.getItem("key") }
  		});
  	}

    public getUserInfo(id){
      return this.httpClient.get(this.REST_API_SERVER + "admin/user_info&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientOverview(id, department, doctor, limit) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/overview&patient_id="+id+"&department_id="+department+"&doctor_id="+doctor+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatientFiles(id) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/files&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public getDocumentPages(id) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/doc_pages&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getOpenFile(key) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/open_file&key="+key, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getPatients(count, search, department){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/patients&limit="+count+"&search="+search+"&department="+department  , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getMyPatients(count, search, tag){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/my_patients&limit="+count+"&search="+search+"&tag="+tag  , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }


    public getPatientsToday(count, search, date){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/patients_today&limit="+count+"&search="+search+"&date="+date , {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public getSymptoms(id, doc){
      return this.httpClient.get(this.REST_API_SERVER + "patient/symptoms&patient_id="+id+"&doc="+doc, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getListDocs(){
      return this.httpClient.get(this.REST_API_SERVER + "patient/list_doctors", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getEnquiries(count, search){
      return this.httpClient.get(this.REST_API_SERVER + "reception/enquiries&limit="+count+"&search="+search, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getEnquiry(id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/enquiry_single&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoals(level, category){
      return this.httpClient.get(this.REST_API_SERVER + "goals/library&level="+level+"&category="+category, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalStats(){
      return this.httpClient.get(this.REST_API_SERVER + "goals/stats", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSubGoals(goal_id){
      return this.httpClient.get(this.REST_API_SERVER + "goals/subgoals&goal_id="+goal_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalCategories(){
      return this.httpClient.get(this.REST_API_SERVER + "goals/categories", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoalLevels(){
      return this.httpClient.get(this.REST_API_SERVER + "goals/levels", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSteps(id){
      return this.httpClient.get(this.REST_API_SERVER + "goals/goal_steps&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRequirements(id){
      return this.httpClient.get(this.REST_API_SERVER + "goals/goal_requirements&goal_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChat(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/chat_thread_therapist&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtActivities(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/ht_activities&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGoal(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/view_goal&goal="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDailyTasks(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/daily_tasks&goal="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHowto(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/goal_howto&goal="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTutorials(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/ht_tutorials&goal_id="+id, {

        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUploads(id){
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/ht_uploads&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getVideo(video_id) {
      return this.httpClient.get(this.REST_API_SERVER + "hometherapy/get_file&video_id="+video_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBills(limit, offset, search, mode, date, widesearch, paymentMode){
      return this.httpClient.get(this.REST_API_SERVER + "reception/bills&limit="+limit+"&offset="+offset+"&search="+search+"&mode="+mode+"&date="+date+"&widesearch="+widesearch+"&pm="+paymentMode, {
        headers: { 'key': localStorage.getItem("key") }
      });
      
    }

    public getSingleBill(id){
      return this.httpClient.get(this.REST_API_SERVER + "reception/view_bill&bill_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBillHeads(mode=1, type=1){
      return this.httpClient.get(this.REST_API_SERVER + "reception/bill_heads&mode="+mode+"&type="+type, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getBillCategories(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/bill_categories", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCancelBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/cancel_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getEvents(limit, mode){
      return this.httpClient.get(this.REST_API_SERVER + "reception/events&limit="+limit+"&mode="+mode, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUserProfile(){
      return this.httpClient.get(this.REST_API_SERVER + "account/profile", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getStaffDetails(){
      return this.httpClient.get(this.REST_API_SERVER + "account/load_staff_details&user_id=", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getStaffDetailsWithId(id){
      return this.httpClient.get(this.REST_API_SERVER + "account/load_staff_details&user_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSdList(){
      return this.httpClient.get(this.REST_API_SERVER + "account/staff_details_list", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUserLeaves(){
      return this.httpClient.get(this.REST_API_SERVER + "account/leaves", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDepts(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/list_departments", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTags(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/list_tags", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDepts2(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/list_departments", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDept(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/get_department", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTherapists(dept_id=0){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/list&dept_id="+dept_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSlots(date, therapist){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/get_free_slots&date="+date+"&therapist="+therapist, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNotibar(){
      return this.httpClient.get(this.REST_API_SERVER + "account/notifications_bar", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNextPatientId(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/next_patient_id", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getNotifications(limit, search){
      return this.httpClient.get(this.REST_API_SERVER + "account/notifications&limit="+limit+"&search="+search, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getConsultations(date, department){
      return this.httpClient.get(this.REST_API_SERVER + "reception/consultations&date="+date+"&department="+department , {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUser(){
      return this.httpClient.get(this.REST_API_SERVER + "account/user", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUsers(limit, search){
      return this.httpClient.get(this.REST_API_SERVER + "admin/users&limit="+limit+"&search="+search, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getForms(){
      return this.httpClient.get(this.REST_API_SERVER + "patient/list_forms", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getForm(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/get_form&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getFormSubmissions(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/submissions&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public getUnifiedForms(offset, patient_id, limit){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/unified_forms&offset="+offset+"&patient_id="+patient_id+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUnifiedForm(form_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/unified_form&form_id="+form_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUnifiedCredentials(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/ufc&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getResponseTypes(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/response_types", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSkipUnifiedForm(form_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/skip_unified_form&form_id="+form_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUnifiedFormsBuilder(offset, patient_id, limit){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/unified_forms&offset="+offset+"&patient_id="+patient_id+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRemarksHistory(id, showAll){
      return this.httpClient.get(this.REST_API_SERVER + "patient/remarks_history&id="+id+"&show_all="+showAll, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSdHistory(id, showAll){
      return this.httpClient.get(this.REST_API_SERVER + "patient/sd_history&id="+id+"&show_all="+showAll, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRecentUpdates(limit, date, date2){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/recent_updates&limit="+limit+"&date="+date+"&date2="+date2, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPbs(patient_id, submission_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/pediatric_balance_scale&patient_id="+patient_id+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getMas(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_mas&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getTug(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_tug&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAsd(patient_id, submission_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_asd&patient_id="+patient_id+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    
    public getIndt(section_id, patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/indt&section_id="+section_id+"&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDieticsTitle(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/dietics_form&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDietics1(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_dietics1&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDietics2(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_dietics2&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDieticsAM(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_dietics_am&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDieticsBiochem(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_dietics_biochem&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDieticsFfq(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_ffq&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDieticsFinal(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_dietics_final&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPesAc(keyword){
      return this.httpClient.get(this.REST_API_SERVER + "patient/pes_autocomplete&keyword="+keyword, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDieticsIddsi(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_dietics_iddsi&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAudioEval(patient_id, test_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/load_audio_eval&patient_id="+patient_id+"&test_id="+test_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getGraphData( patient_id,graph_type){
      return this.httpClient.get(this.REST_API_SERVER +"patient/load_growth_chart&patient_id="+patient_id+"&graph_type="+graph_type, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUserGraphData( patient_id,graph_type){
      return this.httpClient.get(this.REST_API_SERVER +"patient/load_gc_user&patient_id="+patient_id+"&graph_type="+graph_type, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    /*post */

    public postSaveIndt(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_indt", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveIndtComment(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_indt_comment", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPatientTag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_patient_tag", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSymTag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_sym_tag", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeletePatientTag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_tag", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDishchargeForm (data) {
      return this.httpClient.post(this.REST_API_SERVER + "discharge/discharge", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveAudioEval(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_audio_eval", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postGraphData(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_gc_data",data,{
        headers: {'key': localStorage.getItem("key")}
      });
    }

    public postDeleteGraphData(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_gc_data",data,{
        headers: {'key': localStorage.getItem("key")}
      });
    }

    public postSaveDietics1(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics1", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveDietics2(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics2", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsAM(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_am", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditDieticsAM(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit_dietics_am", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteIntake(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_dietics_intake", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteBiochem(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_dietics_biochem", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsIntake(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_intake", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsBiochem(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_biochem", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsRecall(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_recall", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsFfq(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_ffq", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsFfqItem(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_ffq_item", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postRemoveDieticsFfq(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/remove_dietics_ffq", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsFinal(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_final", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsIntervention(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_intervention", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDieticsIddsi(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_dietics_iddsi", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdateAsd(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/update_asd", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddMas(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_mas", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddTug(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_tug", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteTug(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_tug", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteMas(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_mas", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    public postTugAd(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_tug_ad", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPbsAnswer(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_pbs_answer", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postPbsTextVal(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_pbs_text_val", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveUnifiedForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_unified_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSignUnifiedForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/sign_unified_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    

    public postNewUnifiedForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_unified_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUfQuestion(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_uf_question", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteUfQuestion(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_uf_question", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postUfHeading(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_uf_heading", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAssessmentForms(){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/assessment_forms", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getAssessmentForm(form_id, patient_id, submission_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/assessment_form&form_id="+form_id+"&patient_id="+patient_id+"&submission_id="+submission_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postNewAssessmentForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_assessment_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAssessmentQuestion(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_assessment_question", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteAssessmentQuestion(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_assessment_question", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postAssessmentHeading(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/new_assessment_heading", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSaveAssessmentForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_assessment_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postSurveyResponse(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_survey_response", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHbData(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/save_humanbody", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postClearNotifications(){
      return this.httpClient.get(this.REST_API_SERVER + "account/clear_notifications", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    public postNotes(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/add_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAutoSaveNotes(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/autosave_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditNotes(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postRecNotes(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    public postDeleteRecNote(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postConsultation(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_consultation", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postCancelConsultation(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/cancel_consultation", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMedications(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/prescribe_medications", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDevices(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/prescribe_devices", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postProsthetics(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/prescribe_prosthetics", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postTests(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/prescribe_tests", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postRefer(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/refer", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDischarge(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/discharge", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }


    public postFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/upload", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postFileUf(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/upload_unified", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postEditFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit_file", data, {
        headers: { 'key': localStorage.getItem("key") }
        
      });
    }

    public postDeleteFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_file", data, {
        headers: { 'key': localStorage.getItem("key") }
        
      });
    }

    public postReportIssue(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/report_issue", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postProfile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/edit_profile", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/new_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdateBill(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/update_bill", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postForm(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_form", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public endSession(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/end_session", data, {
        headers: { 'key': localStorage.getItem("key") }
      })
    }

    public startSession(data){
      return this.httpClient.post(this.REST_API_SERVER + "therapist/start_session", data, {
        headers: { 'key': localStorage.getItem("key") }
      })
    }

    public postRegistration(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/register", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAppointment(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_appointment", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAppointmentEnquiry(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_appointment_enquiry", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postMoreAppointments(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_more_appointments", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEnquiry(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_enquiry", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEvent(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_event", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public postSlotNote(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_slotnote", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEnquiryAssignment(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/assign_enquiry", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postPassword(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/update_password", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postStaffDetails(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/save_staff_details", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteSdMore(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/delete_sd_more", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postLeave(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/mark_leave", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postLeaveApplication(data) {
      return this.httpClient.post(this.REST_API_SERVER + "admin/apply_leave", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public cancelAppointment(type, appointment_id) {
      var data = {"type":type, "appointment_id":appointment_id};
      return this.httpClient.post(this.REST_API_SERVER + "reception/cancel", data,  {
        headers: { 'key': localStorage.getItem("key") }
      } ); 
    }

    public postReschedule(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/reschedule", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkBillPaid(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_bill_paid", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteEnquiry(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_enquiry", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteNotification(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "account/delete_notification", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteEvent(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_event", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkPresent(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_present", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkDischarge(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_discharge", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkComplete(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_complete", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkConsultation(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_consultation", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUndoCompleted(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/undo_completed", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public postMarkAbsent(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/mark_absent", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUndoAbsent(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "therapist/undo_absent", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postClearSlot(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/clear_slot", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkHoliday(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_holiday", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUnmarkHoliday(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/unmark_holiday", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkLeave(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/mark_leave", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUnmarkLeave(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/unmark_leave", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteSlotNote(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_slot_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postSymptoms(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_symptoms", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAutoSaveSymptoms(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/autosave_symptoms", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdateSymptoms(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/update_symptoms", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postCancelAppointment(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "reception/cancel", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditPatient(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkRead(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "account/mark_read", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteUser(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/delete_user", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteDept(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/delete_dept", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postNewUser(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/add_user", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpdateUser(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/edit_user", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postNewDept(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/add_department", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }
    public postUpdateDept(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "admin/edit_department", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddHowto(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/add_howto", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteHowto(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/delete_howto", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalLib(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postEditGoalLib(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/edit_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteGoalLib(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/delete_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalTask(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_task", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalLevel() {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_level", {}, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddGoalCategory(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_category", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddSubgoal(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_subgoal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddActivity(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_activity", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddStep(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteStep(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/delete_step", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postDeleteReq(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/delete_requirement", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAddReq(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "goals/add_requirement", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postSendChat(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/send_message_therapist", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postAssignGoal(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/assign_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postMarkActComplete(data) {
      
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/mark_act_complete", data, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postUpload(data) {
      return this.httpClient.post(this.REST_API_SERVER + "hometherapy/tutorial_upload", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postAddTag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/add_tag", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteTag(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_tag", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteUfFile(data) {
      return this.httpClient.post(this.REST_API_SERVER + "therapist/delete_uf_file", data, {
          headers: { 'key': localStorage.getItem("key") }
      });
    }

    public login(data) {
      return this.httpClient.post(this.REST_API_SERVER + "account/login", data);
    }




    //statistics

    public getStatOverview(val){
      return this.httpClient.get(this.REST_API_SERVER + "admin/stats_overview&department="+val, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getStatRevenue(period, dept, category){
      return this.httpClient.get(this.REST_API_SERVER + "admin/revenue&period="+period+"&dept="+dept+"&category="+category, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }




    //Chat & Homeprogram

    public validateUser() {
      return this.httpClient.get(this.HT_API + "chat/validate_user", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHometherapy(phone){
      return this.httpClient.get(this.HT_API + "hometherapy/check_patient&phone="+phone, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChatRoom(phone){
      return this.httpClient.get(this.HT_API + "hometherapy/get_chatroom&phone="+phone, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getChatHistory(roomId){
      return this.httpClient.get(this.HT_API + "chat/history_web&room_id="+roomId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getMoreChatHistory(roomId, chatId){
      return this.httpClient.get(this.HT_API + "chat/history_web_more&room_id="+roomId+"&chat_id="+chatId, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSender(){
      return this.httpClient.get(this.HT_API + "hometherapy/get_sender", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getCallingPatient(id){
      return this.httpClient.get(this.HT_API + "hometherapy/get_calling_patient&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRecipient(phone){
      return this.httpClient.get(this.HT_API + "hometherapy/get_recipient&phone="+phone, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientHt(id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/get_ht&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtPatientGoals(phone, limit){
      return this.httpClient.get(this.HT_API + "therapist/patient_goals&phone="+phone+"&limit="+limit, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtPatientActivities(goal_id){
      return this.httpClient.get(this.HT_API + "therapist/goal_activities&goal_id="+goal_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtGoalUploads(goal_id){
      return this.httpClient.get(this.HT_API + "therapist/goal_uploads&goal_id="+goal_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtGoalLevels(){
      return this.httpClient.get(this.HT_API + "therapist/goal_levels", {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public getHtGoalCategories(){
      return this.httpClient.get(this.HT_API + "therapist/goal_categories", {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public getHtLibraryGoals(level, category){
      return this.httpClient.get(this.HT_API + "therapist/library_goals&level="+level+"&category="+category, {
        headers: { 'key': localStorage.getItem("key") }
      }); 
    }

    public postHtAssignGoals(data){
      return this.httpClient.post(this.HT_API + "therapist/assign_goals", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHtSendPush(ids){
      let data = {'goals': ids};
      return this.httpClient.post(this.HT_API + "therapist/send_push", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHtSendPushGoalComplete(id){
      let data = {'goal_id': id};
      return this.httpClient.post(this.HT_API + "therapist/send_push_goal_complete", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHtMarkGoal(data){
      return this.httpClient.post(this.HT_API + "therapist/mark_goal", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtPatientUploads(goal_id, phone, offset){
      return this.httpClient.get(this.HT_API + "therapist/patient_uploads&goal_id="+goal_id+"&phone="+phone+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtGoalTutorials(goal_id, offset){

      return this.httpClient.get(this.HT_API + "therapist/goal_tutorials&goal_id="+goal_id+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postHtSymptoms(data){
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_symptoms_ht", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getHtPatientOverview(id) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/ht_overview&patient_id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      } );
    }

    public postChatFile(data) {
      return this.httpClient.post(this.HT_API + "therapist/upload_chat_file", data, {
        headers: { 'key': localStorage.getItem("key") },
        reportProgress: true,
      });
    }

    public postCallEnd(data) {
      return this.httpClient.post(this.HT_API + "chat/push", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postLestData(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_lest", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }
    public postDenverCoords(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_denver_coords", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postIddsiCoords(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_iddsi_coords", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDenverComments(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/save_denver_comments", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDeleteLastDenver(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_denver_line", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDeleteLastLest(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_last_lest", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postRecordGc(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/record_gc", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDeleteCanvasCoords(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_canvas_coords", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDeleteDenverComments(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/delete_denver_comments", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postEditDenverComments(data) {
      return this.httpClient.post(this.REST_API_SERVER + "patient/edit_denver_comments", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public getGchartCoords(patient_id) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/gc_coords&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLestData(patient_id, type, datestring) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/lest_data&patient_id="+patient_id+"&type="+type+"&datestring="+datestring, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getIddsiCoords(patient_id) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/iddsi_coords&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDenverCoords(patient_id, datestring) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/denver_coords&patient_id="+patient_id+"&datestring="+datestring, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDenverComments(patient_id, type) {
      return this.httpClient.get(this.REST_API_SERVER + "patient/denver_comments&patient_id="+patient_id+"&type="+type, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getSciToken() {
      return this.httpClient.get(this.REST_API_SERVER + "account/sci_token", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getToolsForms() {
      return this.httpClient.get(this.REST_API_SERVER + "therapist/tools_forms", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    
    public getTherapistsAc(term){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/autocomplete&term="+term, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getDeptAc(term){
      return this.httpClient.get(this.REST_API_SERVER + "departments/autocomplete&term="+term, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postBillHead(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/add_bill_head", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postEditBillHead(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/edit_bill_head", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public postDeleteBillHead(data) {
      return this.httpClient.post(this.REST_API_SERVER + "reception/delete_bill_head", data, {
        headers: { 'key': localStorage.getItem("key") },
        //reportProgress: true,
      });
    }

    public getPaymentModes(){
      return this.httpClient.get(this.REST_API_SERVER + "reception/payment_modes", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEditScheduleNote(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/edit_schedule_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRecentRegistrations(date1="", date2="", offset=0, sort=1){
      return this.httpClient.get(this.REST_API_SERVER + "admin/recent_registrations&date1="+date1+"&date2="+date2+"&offset="+offset+"&sort="+sort, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getEnquiryNotes(enquiry){
      return this.httpClient.get(this.REST_API_SERVER + "reception/enquiry_notes&enquiry_id="+enquiry, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postEnquiryNote(data){
      return this.httpClient.post(this.REST_API_SERVER + "reception/save_enquiry_note", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLeaveApplications(status=0,show_after=0, user=0){
      return this.httpClient.get(this.REST_API_SERVER + "admin/leave_applications&status="+status+"&show_after="+show_after+"&user="+user, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getUserLeaveApplications(){
      return this.httpClient.get(this.REST_API_SERVER + "account/leave_applications", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getLaPendingCount(){
      return this.httpClient.get(this.REST_API_SERVER + "admin/la_pending_count", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postUpdateLaStatus(data){
      return this.httpClient.post(this.REST_API_SERVER + "admin/update_la_status", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postDeleteLa(data){
      return this.httpClient.post(this.REST_API_SERVER + "account/delete_la", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientBills(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/bills&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getVisitBilling(visit_id){
      return this.httpClient.get(this.REST_API_SERVER + "patient/visit_billing&visit_id="+visit_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getConfirmVisitPayment(visit_id){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/confirm_visit_payment&visit_id="+visit_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getPatientTherapyDates(patient_id){
      return this.httpClient.get(this.REST_API_SERVER + "admin/patient_therapy_dates&patient_id="+patient_id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getRemarkStatus(patient_id, date=""){
      return this.httpClient.get(this.REST_API_SERVER + "therapist/remark_status&patient_id="+patient_id+"&date="+date, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    /* inventory*/

    public getInvMyRequests(offset, id){
      return this.httpClient.get(this.INVENTORY_API + "requests/my_requests&offset="+offset+"&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postInvRequest(data){
      return this.httpClient.post(this.INVENTORY_API + "requests/new", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
    
    public postInvDeleteRequest(data){
      return this.httpClient.post(this.INVENTORY_API + "requests/delete", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postInvApproveRequest(data){
      return this.httpClient.post(this.INVENTORY_API + "requests/approve", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postInvUserConfirmation(data){
      return this.httpClient.post(this.INVENTORY_API + "requests/user_confirmation", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postInvUserConfirmationPartial(data){
      return this.httpClient.post(this.INVENTORY_API + "requests/user_confirmation_partial", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public postInvHodApproveRequest(data){
      return this.httpClient.post(this.INVENTORY_API + "requests/hod_approve", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getInvInventory(search, offset) {
      return this.httpClient.get(this.INVENTORY_API + "inventory/list_main&search="+search+"&offset="+offset, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
  
    public postInvNewStock(data) {
      return this.httpClient.post(this.INVENTORY_API + "inventory/add_main", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
  
    public postInvEditStock(data) {
      return this.httpClient.post(this.INVENTORY_API + "inventory/edit_main", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
  
    public postInvDeleteStock(data) {
      return this.httpClient.post(this.INVENTORY_API + "inventory/delete_main", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getInvOrders(offset, date, vendor) {
      return this.httpClient.get(this.INVENTORY_API + "orders/list&main=1&offset="+offset+"&date="+date+"&vendor="+vendor, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getInvOrderVendors() {
      return this.httpClient.get(this.INVENTORY_API + "orders/list_vendors&main=1", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
  
    public getInvOrder(id) {
      return this.httpClient.get(this.INVENTORY_API + "orders/view&main=1&id="+id, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }
  
    public postInvOrderBody(data) {
      return this.httpClient.post(this.INVENTORY_API + "orders/new&main=1", data, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getInventoryAc(term) {
      return this.httpClient.get(this.INVENTORY_API + "inventory/autocomplete&term="+term+"&main=1", {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getInvFetchItem(name, code) {
      return this.httpClient.get(this.INVENTORY_API + "requests/fetch_item&name="+name+"&code="+code, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

    public getInvAssetPhoto(fileKey) {
      return this.httpClient.get(this.INVENTORY_API + "assets/get_file_url&main=1&fileKey="+fileKey, {
        headers: { 'key': localStorage.getItem("key") }
      });
    }

}
