import { BrowserModule } from '@angular/platform-browser';
import { NgModule, } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './dashboard/home/home.component';
import { PatientComponent } from './dashboard/patient/patient.component';
import { OverviewComponent } from './dashboard/patient/overview/overview.component';
// import { ActionsComponent } from './dashboard/patient/actions/actions.component';
import { ScheduleComponent } from './dashboard/schedule/schedule.component';
import { PatientsListComponent } from './dashboard/patients-list/patients-list.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
// import { DatePipe } from '@angular/common';
import { FormsModule,ReactiveFormsModule }from'@angular/forms';
import { ReceptionistHomeComponent } from './dashboard/receptionist-home/receptionist-home.component';
import * as bootstrap from 'bootstrap';
import * as $ from 'jquery';
import * as moment from 'moment';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ChartsModule } from 'ng2-charts';

import { PersonalDetailsComponent } from './dashboard/patient/personal-details/personal-details.component';
import { EnquiryComponent } from './dashboard/enquiry/enquiry.component';
import { BillingComponent } from './dashboard/billing/billing.component';
import { NotificationComponent } from './dashboard/notification/notification.component';
import { ProfileComponent } from './dashboard/profile/profile.component';
import { EventsComponent } from './dashboard/events/events.component';
import { SymptomsDiagnosisComponent } from './dashboard/patient/symptoms-diagnosis/symptoms-diagnosis.component';
import { IndividualScheduleComponent } from './dashboard/schedule-individual/schedule-individual.component';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { ImageViewerModule } from "ngx-image-viewer";
import { ViewerComponent } from './viewer/viewer.component';
import { StatisticsComponent } from './dashboard/statistics/statistics.component';
import { StatisticsOverviewComponent } from './dashboard/statistics/overview/overview.component';
import { StatisticsDemographyComponent } from './dashboard/statistics/demography/demography.component';
import { StatisticsRevenueComponent } from './dashboard/statistics/revenue/revenue.component';
import { StatisticsDisabilityComponent } from './dashboard/statistics/disability/disability.component';
import { UsersComponent } from './dashboard/users/users.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AssessmentComponent } from './dashboard/assessment/assessment.component';
import { HometherapyGoalsComponent } from './dashboard/hometherapy-goals/hometherapy-goals.component';
import { HometherapyComponent } from './dashboard/patient/hometherapy/hometherapy.component';
import { FormComponent } from './dashboard/patient/form/form.component';
import { ResourceComponent } from './dashboard/resource/resource.component';
import { UnifiedFormComponent } from './dashboard/unified-form/unified-form.component';
import { PatientsTodayComponent } from './dashboard/patients-today/patients-today.component';
import { FormBuilderComponent } from './dashboard/form-builder/form-builder.component';
import { HomeprogramComponent } from './dashboard/patient/homeprogram/homeprogram.component';
import { ReportIssueComponent } from './dashboard/report-issue/report-issue.component';
import { AdultAssessmentComponent } from './dashboard/adult-assessment/adult-assessment.component';
 
import {SocketioService} from './socketio.service';
import { MyPatientsComponent } from './dashboard/my-patients/my-patients.component';
import { PublisherComponent } from './videochat/publisher/publisher.component';
import { SubscriberComponent } from './videochat/subscriber/subscriber.component';
import { ResourceLibComponent } from './dashboard/resource-lib/resource-lib.component';
import { ChatWindowComponent } from './videochat/chat-window/chat-window.component';
import { RecentActivitiesComponent } from './dashboard/recent-activities/recent-activities.component';
import { DenverComponent } from './dashboard/patient/denver/denver.component';
import { Lest0Component } from './dashboard/patient/lest0/lest0.component';
import { Lest3Component } from './dashboard/patient/lest3/lest3.component';
import { Tdsc0Component } from './dashboard/patient/tdsc0/tdsc0.component';
import { Tdsc3Component } from './dashboard/patient/tdsc3/tdsc3.component';
import { CdcmmComponent } from './dashboard/patient/cdcmm/cdcmm.component';
import { PbsComponent } from './dashboard/patient/pbs/pbs.component';
import { MasComponent } from './dashboard/patient/mas/mas.component';
import { TugComponent } from './dashboard/patient/tug/tug.component';
import { AsdChecklistComponent } from './dashboard/patient/asd-checklist/asd-checklist.component';
import { DieticsComponent } from './dashboard/patient/dietics/dietics.component';
import { PersonalComponent } from './dashboard/patient/dietics/personal/personal.component';
import { RosComponent } from './dashboard/patient/dietics/ros/ros.component';
import { Eat10Component } from './dashboard/patient/dietics/eat10/eat10.component';
import { AnthropometricComponent } from './dashboard/patient/dietics/anthropometric/anthropometric.component';
import { BiochemComponent } from './dashboard/patient/dietics/biochem/biochem.component';
import { FfqComponent } from './dashboard/patient/dietics/ffq/ffq.component';
import { FinalComponent } from './dashboard/patient/dietics/final/final.component';
import { IddsiComponent } from './dashboard/patient/dietics/iddsi/iddsi.component';
import { AudioEvaluationComponent } from './dashboard/patient/audio-evaluation/audio-evaluation.component';
import { GrowthChartComponent } from './dashboard/patient/growth-chart/growth-chart.component';
import { GrowChartComponent } from './dashboard/patient/grow-chart/grow-chart.component';
import { DishchargeFormComponent } from './dashboard/patient/dishcharge-form/dishcharge-form.component';
import { UifComponent } from './dashboard/patient/uif/uif.component';
import { TagsComponent } from './dashboard/tags/tags.component';
import { IndtComponent } from './dashboard/patient/indt/indt.component';
import { AnalyticsComponent } from './dashboard/analytics/analytics.component';
import { SubmissionsComponent } from './dashboard/patient/submissions/submissions.component';
import { AppointmentsComponent } from './dashboard/patient/appointments/appointments.component';
import { StaffDetailsComponent } from './dashboard/staff-details/staff-details.component';
import { StaffDetailsListComponent } from './dashboard/staff-details-list/staff-details-list.component';
import { StaffDetailsViewComponent } from './dashboard/staff-details-view/staff-details-view.component';
import { GenerateBillComponent } from './dashboard/generate-bill/generate-bill.component';
import { BillHeadsComponent } from './dashboard/bill-heads/bill-heads.component';
import { BillComponent } from './dashboard/bill/bill.component';
import { RecentRegistrationsComponent } from './dashboard/recent-registrations/recent-registrations.component';





@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HomeComponent,
    PatientComponent,
    OverviewComponent,
    ScheduleComponent,
    PatientsListComponent,
    LoginComponent,
    ReceptionistHomeComponent,
    PersonalDetailsComponent,
    EnquiryComponent,
    BillingComponent,
    NotificationComponent,
    ProfileComponent,
    EventsComponent,
    SymptomsDiagnosisComponent,
    IndividualScheduleComponent,
    ViewerComponent,
    StatisticsComponent,
    StatisticsOverviewComponent,
    StatisticsDemographyComponent,
    StatisticsRevenueComponent,
    StatisticsDisabilityComponent,
    UsersComponent,
    AssessmentComponent,
    HometherapyGoalsComponent,
    HometherapyComponent,
    FormComponent,
    ResourceComponent,
    UnifiedFormComponent,
    PatientsTodayComponent,
    FormBuilderComponent,
    HomeprogramComponent,
    ReportIssueComponent,
    AdultAssessmentComponent,
    MyPatientsComponent,
    PublisherComponent,
    SubscriberComponent,
    ResourceLibComponent,
    ChatWindowComponent,
    RecentActivitiesComponent,
    DenverComponent,
    Lest0Component,
    Lest3Component,
    Tdsc0Component,
    Tdsc3Component,
    CdcmmComponent,
    PbsComponent,
    MasComponent,
    TugComponent,
    AsdChecklistComponent,
    DieticsComponent,
    PersonalComponent,
    RosComponent,
    Eat10Component,
    AnthropometricComponent,
    BiochemComponent,
    FfqComponent,
    FinalComponent,
    IddsiComponent,
    AudioEvaluationComponent,
    GrowthChartComponent,
    GrowChartComponent,
    DishchargeFormComponent,
    UifComponent,
    TagsComponent,
    IndtComponent,
    AnalyticsComponent,
    SubmissionsComponent,
    AppointmentsComponent,
    StaffDetailsComponent,
    StaffDetailsListComponent,
    StaffDetailsViewComponent,
    GenerateBillComponent,
    BillHeadsComponent,
    BillComponent,
    RecentRegistrationsComponent,
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    ImageViewerModule.forRoot(),
    FullCalendarModule,
    AutocompleteLibModule,
    ChartsModule
  ],
  providers: [SocketioService],
  bootstrap: [AppComponent]
})
export class AppModule { }
