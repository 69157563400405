import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-recent-registrations',
  templateUrl: './recent-registrations.component.html',
  styleUrls: ['./recent-registrations.component.scss']
})
export class RecentRegistrationsComponent implements OnInit {

  constructor(private dataService: DataService) { }

  data:any  = {status:0, patients:[], date1:"", date2:"", count:0}
  date1:any = "";
  date2:any = "";
  limit:any = 30;
  offset:any = 0;
  sort:any = 1;
  loading:any = 0;

  ngOnInit() {
    this.loadData();
  }

  loadData(){
    this.loading = 1;
    this.dataService.getRecentRegistrations(this.date1, this.date2, this.offset, this.sort).subscribe( (data: any = {status:0, patient:[], date1:"", date2:"", msg:""} )=> {
      this.loading = 0;
      this.data = data;
      if(data.status == 0){
        this.date1 = data.date1;
        this.date2 = data.date2;
      }
      
      
    } );
  }

  filterByDate(){
    this.offset = 0;
    this.loadData();
  }

  updateSort(val){
    this.sort = val;
    this.offset = 0;
    this.loadData();
  }

  paginate(dir){
    if(dir == 2) {
      this.offset += this.limit;
    } else {
      this.offset -= this.limit;
    }

    this.loadData();

  }

  downloadCsv(){
    let key = localStorage.getItem("key");

    let url = this.dataService.REST_API_SERVER + "view/recent_registrations&date1="+this.date1+"&date2="+this.date2+"&offset="+this.offset+"&sort="+this.sort+"&key="+key;
    window.open(url);


  }

  loadTherapyDates(patient){
    this.dataService.getPatientTherapyDates(patient.id).subscribe( (data: any )=> {
      patient.therapy_dates = data;
      
    } ); 
  }

}
